<template>
  <span v-if="modules && modules['invoices']">
    <fa-icon icon="file-pdf" :class="{...iconSize, clickable: true}" @click="getPdf" />
  </span>
</template>

<style scoped lang="scss">
.clickable {
    cursor: pointer;
}
</style>

<script>
import * as FileDownload from 'js-file-download';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

library.add(faFilePdf);

export default {
  components: {
  },
  props: ['bookingId', 'invoiceId', 'size'],
  data: () => {
    return {
      iconSize: {
      }
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  mounted () {
    this.iconSize = {};
    if (this.size) {
      this.iconSize[this.size] = true;
    }
  },
  methods: {
    getPdf () {
      this.$http.get(`/bookings/${this.bookingId}/invoices/${this.invoiceId}`, {
        headers: { Accept: 'application/pdf' },
        responseType: 'blob'
      })
        .then((res) => {
          const disposition = res.headers['content-disposition'];
          const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
          FileDownload(res.data, filename);
        })
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  }
};
</script>
