<template>
  <div v-if="modules && modules['invoices']">
    <b-card title="">
      <b-form-group label-cols-sm="4" label-cols-lg="3" label="Date">
        <DatePicker v-model="item.date" :disabled="noEdit" input-class="form-control" @input="dateChanged($event);" />
      </b-form-group>
      <div v-if="item.draft || item.refNumber">
        <a v-if="!showDetails && !item.refNumber && !noEdit" href="#" @click.prevent="showDetails = true">Edit details</a>
        <div v-if="showDetails || item.refNumber || noEdit">
          <b-form-group v-if="item.refNumber" label-cols-sm="4" label-cols-lg="3" label="Invoice no.">
            <b-form-input id="refNumber" v-model="item.refNumber" type="text" disabled />
          </b-form-group>
          <b-form-group v-if="item.bankReference" label-cols-sm="4" label-cols-lg="3" label="Bank Reference no.">
            <b-form-input id="bankReference" v-model="item.bankReference" type="text" disabled />
          </b-form-group>
          <b-form-group v-if="item.deadline" label-cols-sm="4" label-cols-lg="3" label="Deadline">
            <b-form-input id="deadline" :value="moment(item.deadline).format('L')" type="text" disabled />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="First name">
            <b-form-input id="firstname" v-model="item.firstname" type="text" :disabled="noEdit" placeholder="First Name" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Last name">
            <b-form-input id="lastname" v-model="item.lastname" type="text" :disabled="noEdit" placeholder="Last Name" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Company name">
            <b-form-input id="companyname" v-model="item.companyname" type="text" :disabled="noEdit" laceholder="Company name" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Street">
            <b-form-input id="street" v-model="item.street" type="text" :disabled="noEdit" placeholder="Street" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="City">
            <b-form-input id="city" v-model="item.city" type="text" :disabled="noEdit" placeholder="City" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Zip code">
            <b-form-input id="zip" v-model="item.zip" type="text" :disabled="noEdit" placeholder="Zip" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="State">
            <b-form-input id="state" v-model="item.state" type="text" :disabled="noEdit" placeholder="State" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Country">
            <b-form-input
              id="country" v-model="item.country" :disabled="noEdit" :formatter="toUpperCase" maxlength="3" type="text"
              placeholder="ISO 3166 Country Code"
            />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Overdue penalty (% per day)">
            <b-form-input id="overduePenalty" v-model="item.overduePenalty" :disabled="noEdit" type="text" placeholder="0.5" />
          </b-form-group>
        </div>
        <b-row v-if="item && item.summary">
          <b-col>
            <b-table striped hover :fields="invoiceArticleFields" :items="item.invoiceArticles" />
            <b-row>
              <b-col />
              <b-col>
                <table class="table">
                  <tr>
                    <td>
                      Sum in EUR
                    </td>
                    <td>
                      <strong>
                        {{ item.summary.sumTaxedRounded }}
                      </strong>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-row v-if="parseFloat(item.fines) > 0">
              <b-col />
              <b-col>
                <table class="table">
                  <tr>
                    <td>
                      Fine for delay<br>
                      {{ item.startDate| moment('L') }} - {{ item.endDate| moment('L') }}
                    </td>
                    <td>
                      <strong>
                        {{ item.fines }}
                      </strong>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <br>
  </div>
</template>

<script>
import * as _ from 'lodash';
import * as moment from 'moment';

import DatePicker from '../../../components/date-picker';

export default {
  components: {
    DatePicker
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    noEdit: Boolean
  },
  data () {
    return {
      showDetails: false,
      moment,
      dateFormat: DatePicker.format(),
      invoiceArticleFields: [
        { key: 'name' },
        { key: 'amount' },
        { key: 'price' },
        // eslint-disable-next-line
        { key: 'startDate', label: 'Period', formatter: (value, key, item) => {
          return (`${moment(item.startDate).utc().format(DatePicker.format())} - ${moment(item.endDate).utc().format(DatePicker.format())}`);
        } },
        { key: 'startValue', label: 'Initial value' },
        { key: 'endValue', label: 'Final value' },
        { key: 'summary', label: 'Sum with TAX', formatter: value => (value ? value.sumTaxedRounded : '') }
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    dateChanged (date) {
      this.$emit('dateChange', date);
    },
    toUpperCase () {
      return  _.toUpperCase;
    },
  }
};
</script>
