<template>
  <div v-if="modules && !!modules['services']">
    <b-modal ref="serviceorder" size="lg" :lazy="true" no-enforce-focus hide-header>
      <blueprint-data
        v-if="optionalservicesPath" :params="optionalservicesParams" :api-route-path="optionalservicesPath" :fields="optionalServicesFields" no-new no-search
        no-actions
      >
        <template v-slot:price="{ item }">
          {{ item.price?parseFloat(item.price).toFixed(2):0 }}<span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span><span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
        <template v-slot:price_tax="{ item }">
          {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION_PER_M2'">/month/m<sup>2</sup></span>
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span>
          <span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
        <template v-slot:serviceId="{ item }">
          {{ item.service.name }}
        </template>
        <template v-slot:_actions="{ item }">
          <a href="#" class="text-primary" title="Order" @click.prevent="orderService(item)">
            <fa-icon icon="shopping-cart" /> Order
          </a>
        </template>
      </blueprint-data>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal>

    <b-card title="Included Services">
      <blueprint-data
        v-if="includedServicesPath" :params="servicesParams" :api-route-path="includedServicesPath" :fields="includedServiceFields"
        no-new
        no-search
        no-actions
      >
        <template v-slot:serviceId="{ item }">
          {{ item.service.name }}
        </template>
        <template v-slot:price="{ item }">
          {{ item.price?parseFloat(item.price).toFixed(2):0 }}<span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span><span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
        <template v-slot:price_tax="{ item }">
          {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION_PER_M2'">/month/m<sup>2</sup></span>
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span>
          <span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
      </blueprint-data>
    </b-card>
    <br>
    <b-card title="Ordered Services">
      <blueprint-data v-if="serviceOrdersPath" :params="servicesParams" :api-route-path="serviceOrdersPath" :fields="serviceOrderFields" no-search no-actions>
        <template v-slot:new>
          <b-button variant="success" @click="newServiceOrder">
            <fa-icon icon="plus" /> New
          </b-button>
        </template>
        <template v-slot:serviceId="{ item }">
          {{ item.service.name }}
        </template>
        <template v-slot:end="{ item }">
          {{ formatDate(item.end) }}
        </template>
        <template v-slot:ordered="{ item }">
          {{ formatDate(item.ordered) }}
        </template>
        <template v-slot:activated="{ item }">
          {{ formatDate(item.activated) }}
        </template>
        <template v-slot:status="{ item }">
          <b-dropdown size="sm" :disabled="item.status==='CANCELED' || item.status=='CLOSED'" :variant="getStatusVariant(item.status)" :text="getStatusText(item.status)">
            <b-dropdown-item :disabled="item.status!=='NEW' || role === 'USER' || role === 'AUDITOR'" @click="setStatus(item, 'CONFIRMED')">
              Confirm
            </b-dropdown-item>
            <b-dropdown-item :disabled="(item.status!=='NEW' && item.status!=='CONFIRMED') || role === 'USER' || role === 'AUDITOR'" @click="setStatus(item, 'ACTIVATED')">
              Activate
            </b-dropdown-item>
            <b-dropdown-item :disabled="item.status!=='ACTIVATED'" @click="setStatus(item, 'CLOSED')">
              End
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item :disabled="item.status==='CANCELED' || item.status=='CLOSED' || role === 'USER' || role === 'AUDITOR'" @click="setStatus(item, 'CANCELED')">
              Cancel
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template v-slot:price="{ item }">
          {{ item.price?parseFloat(item.price).toFixed(2):0 }}<span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span><span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
        <template v-slot:price_tax="{ item }">
          {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION_PER_M2'">/month/m<sup>2</sup></span>
          <span v-if="item.service.type === 'MONTH_SUBSCRIPTION'">/month</span>
          <span v-if="item.service.type === 'PER_ORDER'">/per order</span>
        </template>
      </blueprint-data>
    </b-card>
    <br>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import DatePicker from '../../../components/date-picker';

library.add(faShoppingCart);

export default {
  props: ['bookingId'],
  data () {
    return {
      errors: {},
      optionalservicesParams: {},
      servicesParams: {},
      includedServicesPath: '',
      serviceOrdersPath: '',
      optionalservicesPath: '',
      optionalServicesFields: [
        {
          key: 'serviceId',
          label: 'Service',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          sortable: false,
          slot: true,
        },
        {
          key: 'price_tax',
          label: 'Price + Taxes',
          sortable: false,
          slot: true,
        },
        {
          key: '_actions',
          slot: true,
        },
      ],
      includedServiceFields: [
        {
          key: 'serviceId',
          label: 'Service',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          sortable: false,
          slot: true,
        },
        {
          key: 'price_tax',
          label: 'Price + Taxes',
          sortable: false,
          slot: true,
        },
      ],
      serviceOrderFields: [
        {
          key: 'serviceId',
          label: 'Service',
          sortable: true,
          slot: true,
        },
        {
          key: 'ordered',
          sortable: true,
          slot: true,
        },
        {
          key: 'activated',
          sortable: true,
          slot: true,
        },
        {
          key: 'end',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          sortable: false,
          slot: true,
        },
        {
          key: 'price_tax',
          label: 'Price + Taxes',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  computed: {
    me () {
      return this.$store.getters.me;
    },
    role () {
      return this.$store.getters.me ? this.$store.getters.me.role : 'USER';
    },
    modules () {
      return this.$store.getters.modules;
    },
  },
  watch: {
    modules () {
      if (this.modules && !!this.modules.services) {
        this.initializeServices();
      }
    }
  },
  async created () {
    if (!this.bookingId) {
      throw new Error('Booking id missing for booking acl component.');
    }
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
  },
  mounted () {
    if (this.modules && !!this.modules.services) {
      this.initializeServices();
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return '';
      }
      return moment(date).utc().format(DatePicker.format());
    },
    initializeServices () {
      const id = this.bookingId;
      this.includedServicesPath = `bookings/${id}/includedservices`;
      this.serviceOrdersPath = `bookings/${id}/serviceorders`;
      this.optionalservicesPath = `bookings/${id}/optionalservices`;
    },
    getStatusVariant (status) {
      switch (status) {
        case 'NEW':
          return 'info';
        case 'CONFIRMED':
          return 'warning';
        case 'CANCELED':
          return 'danger';
        case 'ACTIVATED':
          return 'success';
        default:
          return 'dark';
      }
    },
    getStatusText (status) {
      switch (status) {
        case 'NEW':
          return 'New';
        case 'CANCELED':
          return 'Canceled';
        case 'CONFIRMED':
          return 'Confirmed';
        case 'ACTIVATED':
          return 'Active';
        case 'CLOSED':
          return 'Closed';
        default:
          return '-';
      }
    },
    orderService (item) {
      const bid = this.bookingId;
      const oid = item.id;
      return this.$http.put(`bookings/${bid}/serviceorders/rel/${oid}`)
        .then(() => {
          this.$refs.serviceorder.hide();
          this.servicesParams = { // renew ordered services
            timestamp: new Date().getTime(),
          };
        }).catch(this.err);
    },
    setStatus (item, status) {
      const bid = this.bookingId;
      const { id } = item;
      return this.$http.patch(`bookings/${bid}/serviceorders/${id}`, { status })
        .then(() => {
          this.$refs.serviceorder.hide();
          this.servicesParams = { // renew ordered services
            timestamp: new Date().getTime(),
          };
        }).catch(this.err);
    },
    newServiceOrder () {
      this.optionalservicesParams = { // renew optional services
        timestamp: new Date().getTime(),
      };
      this.$refs.serviceorder.show();
    },
    getOptionalServices () {
      const id = this.bookingId;
      return this.$http.get(`bookings/${id}/optionalservices`)
        .then((res) => {
          this.optionalservices = res.data;
        }).catch(this.err);
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },

  },
};
</script>
