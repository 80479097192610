<template>
  <div v-if="modules && modules['invoices']">
    <b-card title="Recipts">
      <blueprint-data
        :new-default-values="newDefaultValues"
        :api-route-path="`/bookings/${bookingId}/receipts`" :fields="fields" no-search
        @updated="$emit('updated')"
      >
        <template v-slot:default="{ state, item }">
          <b-form-group label="Date">
            <DatePicker v-model="item.date" input-class="form-control" />
          </b-form-group>
          <b-form-group label="Payer">
            <b-form-input v-model="item.payer" :state="state('payer')" type="text" placeholder="Payer" />
          </b-form-group>
          <b-form-group label="Description">
            <b-form-input v-model="item.description" :state="state('description')" type="text" placeholder="Description" />
          </b-form-group>
          <b-form-group label="Amount">
            <b-form-input v-model="item.amount" step="0.01" :state="state('amount')" type="number" placeholder="Amount" />
          </b-form-group>
        </template>
        <template v-slot:date="{ item }">
          {{ item.date | moment(dateFormat) }}
        </template>
        <template v-slot:amount="{ item }">
          <!-- TODO: add currency --->
          {{ item.amount | toFixed(2) }}
        </template>
      </blueprint-data>
    </b-card>
    <br>
  </div>
</template>

<script>
import DatePicker from '../../../components/date-picker';

export default {
  components: {
    DatePicker
  },
  props: ['bookingId'],
  data () {
    return {
      errors: {},
      dateFormat: DatePicker.format(),
      newDefaultValues: {
        date: new Date()
      },
      fields: [
        {
          key: 'date',
          label: 'Date',
          slot: true,
          sortable: true
        },
        {
          key: 'payer',
          label: 'Payer',
          slot: false,
          sortable: true
        },
        {
          key: 'description',
          label: 'Description',
          slot: false,
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount',
          slot: true,
          sortable: true
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  }
};
</script>

