<template>
  <b-row v-if="!loading">
    <b-col v-if="role !== 'USER'" :md="4" :lg="4">
      <BookingEditAdmin :booking-id="bookingId" />
    </b-col>
    <b-col :md="role !== 'USER' ? 8 : 12" :lg="role !== 'USER' ? 8 : 12">
      <BookingBalance ref="balance" :booking-id="bookingId" />
      <BookingJobs :booking-id="bookingId" />
      <InvoiceList :booking-id="bookingId" @updated="$refs.balance.update()" />
      <BookingReceipts :booking-id="bookingId" @updated="$refs.balance.update()" />
      <booking-edit-services :booking-id="bookingId" />
      <booking-edit-acl :booking-id="bookingId" />
    </b-col>
  </b-row>
</template>


<script>
import BookingEditAcl from './booking-edit-device-acl.vue';
import BookingEditAdmin from './booking-edit-admin.vue';
import BookingEditServices from '../../../service-modules/services/components/booking-edit-services.vue';
import InvoiceList from '../../../service-modules/invoices/components/invoice-list';
import BookingReceipts from '../../../service-modules/invoices/components/booking-receipts';
import BookingBalance from '../../../service-modules/invoices/components/booking-balance.vue';
import BookingJobs from '../tasks/components/booking-jobs.vue';

export default {
  components: {
    BookingEditAcl,
    BookingEditAdmin,
    BookingEditServices,
    InvoiceList,
    BookingReceipts,
    BookingBalance,
    BookingJobs
  },
  data () {
    return {
      loading: true,
      bookingId: '',
    };
  },
  computed: {
    me () {
      return this.$store.getters.me;
    },
    role () {
      return this.$store.getters.me ? this.$store.getters.me.role : 'USER';
    },
    org () {
      return this.$store.getters.org;
    },
  },
  async mounted () {
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
    this.bookingId = this.$route.params.id;
    this.loading = false;
  },
};
</script>
