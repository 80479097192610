<template>
  <div v-if="modules && modules['invoices']">
    <b-card title="Invoices">
      <blueprint-data
        ref="bld" :new-default-values="newDefaultValues" :api-route-path="`/bookings/${bookingId}/invoices`" :fields="fields" modal-size="lg" no-search
        no-actions
        @updated="$emit('updated')"
      >
        <template v-slot:default="{ item }">
          <b-alert :show="invoiceType === 'SETTLE_ALL'" variant="warning">
            <h5>This is invoice settles all</h5>
          </b-alert>
          <b-alert :show="invoiceType === 'MONTHLY'" variant="warning">
            <h5>This is periodic invocie</h5>
            <p>By creating and confirming invoice will calculate fines for previous period payment delays. After that you can add receipts after invoiced period.</p>
          </b-alert>
          <Invoice :item="item" @dateChange="dateChanged" />
        </template>
        <template v-slot:refNumber="{ item }">
          <router-link :to="{ name: 'bookings.invoice.edit', params: { id: item.id, bookingId: bookingId } }">
            {{ item.refNumber }}
          </router-link>
          <PdfButton class="ml-2" :booking-id="item.bookingId" :invoice-id="item.id" />
        </template>
        <template v-slot:date="{ item }">
          {{ item.date | moment(dateFormat) }}
        </template>
        <template v-slot:status="{ item }">
          <b-badge :variant="getStatusVariant(item.status)">
            {{ getStatusText(item.status) }}
          </b-badge>
        </template>
        <template v-slot:new="{ }">
          <b-button variant="success" @click="newItem">
            <fa-icon icon="plus" /> New
          </b-button>
        </template>
      </blueprint-data>
    </b-card>
    <br>
  </div>
</template>

<script>
import * as _ from 'lodash';
import * as moment from 'moment';

import PdfButton from '../components/pdf-button';
import DatePicker from '../../../components/date-picker';
import Invoice from './invoice.vue';

export default {
  components: {
    PdfButton,
    Invoice,
  },
  props: ['bookingId'],
  data () {
    return {
      invoiceType: 'MONTHLY',
      errors: {},
      date: new Date(),
      disabledDates: {},
      dateFormat: DatePicker.format(),
      fields: [
        {
          key: 'refNumber',
          sortable: true,
          slot: true
        },
        {
          key: 'date',
          label: 'Date',
          slot: true,
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          slot: true,
          sortable: true
        },
      ],
      newDefaultValues: {
      }
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  created () {
    this.newDefaultValues = {
      invoiceType: this.invoiceType,
      date: this.date,
      draft: false,
    };
  },
  methods: {
    dateChanged (date) {
      this.date = date;
      this.newItem();
    },
    newItem () {
      if (this.modules && this.modules['invoices']) {
        this.$http.get(`/bookings/${this.bookingId}`)
         .then(({ data }) => {
           if (data.status === 'CLOSED') {
             // if booking is closed, we can settle all
             this.invoiceType = 'SETTLE_ALL';
           }
           return this.$http.get(`/bookings/${this.bookingId}/invoices/draft`, { params: { invoiceType: this.invoiceType, date: this.date } });
         })
        .then(res => {
          this.newDefaultValues = {
            ...res.data,
            deadline: moment(res.data.date).add({ days: res.data.deadlineDuration }).toDate(),
            invoiceType: this.invoiceType,
            date: this.date,
            draft: true,
          };
        })
        .catch((err) => {
          this.newDefaultValues = {
            invoiceType: this.invoiceType,
            date: this.date,
            draft: false,
          };
          this.err(err);
        })
        .finally(() => {
          this.$refs.bld.newItem(this.newDefaultValues);
        });
      }
    },
    err (err) {
      let message = err.response && err.response.data ? err.response.data.message || err.message : err.message;
      if (err.response && err.response.data && err.response.data.meter) {
        const { meter } = err.response.data;
        message += `... Meter: ${meter.name}`;
        message += ', date: ';
        message += err.response.data.end ?
           moment(err.response.data.end).format(this.dateFormat) :
           moment(err.response.data.start).format(this.dateFormat);
      }
      this.error('Error', message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    toUpperCase () {
      return  _.toUpperCase;
    },
    getStatusVariant (status) {
      switch (status) {
        case 'NEW':
          return 'info';
        case 'CONFIRMED':
          return 'warning';
        case 'CANCELLED':
          return 'danger';
        default:
          return 'dark';
      }
    },
    getStatusText (status) {
      return _.upperFirst(status.toLowerCase());
    },
  }
};
</script>
