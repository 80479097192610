<template>
  <div v-if="modules && modules['invoices'] && balance">
    <b-row>
      <b-col>
        <b-card title="Invoiced">
          {{ balance.invoiced }}
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Payments">
          {{ balance.payments }}
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Balance">
          <span :class="{ 'text-danger': balance.balance < 0 }">{{ balance.balance }}</span>
        </b-card>
      </b-col>
    </b-row>
    <br>
  </div>
</template>

<script>
// TODO: currency
// TODO: update
export default {
  props: ['bookingId'],
  data () {
    return {
      balance: null,
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  mounted () {
    if (!this.bookingId) {
      throw new Error('Booking id missing for booking acl component.');
    } else {
      this.update();
    }
  },
  methods: {
    update () {
      this.$http.get(`bookings/${this.bookingId}/balance`)
      .then(({ data }) => {
        this.balance = data;
      })
      .catch(err => this.error('Error', err.message));
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  },
};
</script>
